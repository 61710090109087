<template>
  <div>
    <div v-if="show.length">
      <div class="flix-list-group" v-for="(date, index) in show" :key="index">
        <div class="flix-list-group-item">
          <div class="flix-text-center">
            <b><flixIcon id="calendar" /> {{ $beautifyDate(date) }}</b>
          </div>
          <hr />
          <div v-if="$manualAppointment(date)">
            <span v-html="$manualAppointment(date)" class="flix-html-small"></span>
          </div>
          <flixIcon id="envelope" /> <span v-html="date.email" /><br />
        </div>
        <editBtn
          :settings="{ block: true }"
          :onClick="
            () => {
              editEntry(date)
            }
          "
        >
          <template v-slot:text>
            {{ $t('message.goTo', { name: $tc('message.appointments', 1) }) }}
          </template>
        </editBtn>
        <bookingDetails :entry="date" />
      </div>
    </div>
    <div v-else>
      <div class="flix-alert flix-alert-info">
        {{ $tc('message.noEntry', 2) }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    bookingDetails() {
      return import('@/components/booking/details')
    }
  },
  props: {
    dates: Object
  },
  data() {
    return {
      checked: [],
      show: [],
      variables: this.$getUserVariables()
    }
  },
  methods: {
    editEntry(date) {
      this.$router.push({
        name: 'dashboardBookingDetails',
        params: {
          user: this.variables.user.md5_id,
          form: this.$route.params.id,
          entryID: date.md5ID
        }
      })
    },
    getDates() {
      Object.keys(this.dates)
        .sort()
        .forEach(
          function (hours) {
            this.dates[hours].forEach(
              function (booking) {
                if (
                  this.checked.indexOf(booking.ID) === -1 &&
                  booking.status === 'commitment'
                ) {
                  this.checked.push(booking.ID)
                  this.show.push(booking)
                }
              }.bind(this)
            )
          }.bind(this)
        )
    }
  },
  mounted() {
    this.getDates()
  },
  computed: {}
}
</script>
<style lang="sass" scoped></style>
